import { initializeApp } from 'firebase/app'
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore/lite'

const firebaseConfig = {
  apiKey: 'AIzaSyDHzli3sudCTlwtNHtyxvaaB_iqxL3BEIY',
  authDomain: 'my-portfolio-rdv1.firebaseapp.com',
  projectId: 'my-portfolio-rdv1',
  storageBucket: 'my-portfolio-rdv1.appspot.com',
  messagingSenderId: '265701944374',
  appId: '1:265701944374:web:0e5429714c63a80d067c97',
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth()
const provider = new GoogleAuthProvider()
export const db = getFirestore(app)
export const storage = getStorage(app)

export const signInWithGoogle = () => signInWithPopup(auth, provider)
